import React, { useState, useEffect } from "react";
import Layout from "../../../Reusable/Tutor/Layout/index";
import Earningclasses from "./earning.module.css";

import { Error, warningSwal } from "../../../Reusable/utils/Toast";
import { Services } from "../../../Service/service";
import TableLoad from "./TableLoad/load";
import { useForm, SubmitHandler } from "react-hook-form";
import { FaRupeeSign } from "react-icons/fa";
import Moment from "react-moment";
import Challenge from "./Challenge/challenge";
import ReactPaginate from "react-paginate";

type Inputs = {
    FromDate?: string;
    ToDate?: string;
};
function Earning() {
    const [Loading, setLoading] = useState<boolean>(true);
    const [showPaginate, setShowPagiate] = useState(true);
    const [Filter, setFilter] = useState<any>(null);
    const RecordCount = 20;

    const [ChallengeId, setChallengeId] = useState<number | null>(null);
    const [pageCount, setpageCount] = useState(1);
    const [forcepage, setforcepage] = useState(0);

    const [earnings, set_Earnings] = useState<any>({
        Tutors_Earnings: [],
        TotalEarnings: 0,
        outstandingAmount: 0,
    });
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const {
        register,
        handleSubmit,
        reset,
        formState: {},
    } = useForm<Inputs>();
    useEffect(() => {
        Tutor_Earnings(0, Filter);
        // eslint-disable-next-line
    }, [Filter]);

    //Fetching  Earnings
    async function Tutor_Earnings(offset: any, body?: any) {
        setLoading(true);
        try {
            const AllClasses = await Services.Tutor_Earnings(
                "POST",
                Filter ? JSON.stringify(body) : null,
                token,
                offset
            );
            setTimeout(() => {
                setLoading(false);
            }, 200);
            set_Earnings({
                Tutors_Earnings: AllClasses.Tutors_Earnings,
                TotalEarnings: AllClasses.TotalEarnings,
                outstandingAmount: AllClasses.outstandingAmount,
            });
            if (
                AllClasses.TotalEarnings.length == 0 ||
                AllClasses.EarningsCount <= RecordCount
            ) {
                setShowPagiate(false);
            } else {
                if (!showPaginate) {
                    setShowPagiate(true);
                }
                setpageCount(
                    Math.ceil(
                        Number(AllClasses?.EarningsCount) / Number(RecordCount)
                    )
                );
            }
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            Error("something went wrong please try again");
        }
    }
    const handlePageChange = (selectedObject: any) => {
        window.scrollTo({ top: 10, behavior: "smooth" });
        setforcepage(selectedObject.selected);
        setLoading(true);

        Tutor_Earnings(selectedObject.selected * RecordCount, Filter);
    };
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        if (data.FromDate && data.ToDate && data.FromDate > data.ToDate) {
            warningSwal(
                "Invalid Date Range",
                "From date Cannot be greater than to date"
            );
            return;
        }
        setFilter(data);
        setLoading(true);
        setforcepage(0);

        /*  try {
            let body = JSON.stringify({ ...data });
            const [summaryData] = await Promise.all([
                Services.Tutor_Earnings("POST", body, token, 0),
             
            ]);

            if (summaryData.Status === 1) {
                set_Earnings({
                    Tutors_Earnings: summaryData.Tutors_Earnings,
                    TotalEarnings: summaryData.TotalEarnings,
                    outstandingAmount: summaryData.outstandingAmount,
                });
                if (
                    summaryData.TotalEarnings.length == 0 ||
                    summaryData.EarningsCount <= RecordCount
                ) {
                    setShowPagiate(false);
                } else {
                    if (!showPaginate) {
                        setShowPagiate(true);
                    }
                    setpageCount(
                        Math.ceil(
                            Number(summaryData?.EarningsCount) /
                                Number(RecordCount)
                        )
                    );
                }
            }

            setLoading(false);
        } catch {
            setLoading(false);
            alert("something went wrong try again");
        } */
    };
    let TableHead: string[] = [
        "Class Date",
        "Class Time",
        "Duration",
        "Batch Name",
        "Type",
        "Credit",
        "Debit",
        "Status",
    ];
    const clearHandler = () => {
        reset();
        setforcepage(0);
        setFilter(null);
    };
    // const replacedash=(str:string)=> )
    return (
        <>
            {ChallengeId && (
                <Challenge
                    modal={ChallengeId}
                    setModal={setChallengeId}
                    getDetails={{
                        Tutor_Earnings,
                        details: earnings?.Tutors_Earnings?.filter(
                            (item: any) => item.TutorEarningID == ChallengeId
                        ),
                    }}
                />
            )}
            {/* <h3>Earnings</h3> */}

            <form
                className={Earningclasses["filter"]}
                onSubmit={handleSubmit(onSubmit)}>
                <input
                    type="text"
                    placeholder="From Date"
                    {...register("FromDate", {
                        required: true,
                    })}
                    onClick={(event) => {
                        const inputElement = event.target as HTMLInputElement;
                        inputElement.type = "date";
                    }}
                />
                <input
                    type="text"
                    placeholder="To Date"
                    {...register("ToDate", {
                        required: true,
                    })}
                    onClick={(event) => {
                        const inputElement = event.target as HTMLInputElement;
                        inputElement.type = "date";
                    }}
                />
                <button>Submit</button>
                <button type="button" onClick={clearHandler}>
                    Clear
                </button>
            </form>
            <div className={Earningclasses["earnings"]}>
                <div>
                    <p>Total Earning</p>
                    <h3>
                        <FaRupeeSign />{" "}
                        {!isNaN(earnings.TotalEarnings)
                            ? Math.round(earnings.TotalEarnings)
                            : 0}
                    </h3>
                </div>
                <div>
                    <p>Outstanding Amount</p>
                    <h3>
                        <FaRupeeSign />{" "}
                        {!isNaN(earnings.outstandingAmount)
                            ? Math.round(earnings.outstandingAmount)
                            : 0}
                    </h3>
                </div>
            </div>
            {Loading ? (
                <TableLoad TableHead={TableHead} />
            ) : (
                <div className={Earningclasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                {TableHead.map((item) => (
                                    <th>{item}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {earnings.Tutors_Earnings.length > 0 ? (
                                earnings.Tutors_Earnings?.map((item: any) => {
                                    return (
                                        <tr>
                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {
                                                        new Date(
                                                            item.Scheduled_StartDate
                                                        )
                                                    }
                                                </Moment>
                                            </td>
                                            <td>{item.Scheduled_StartTime}</td>
                                            <td>
                                                {item.EarningType ==
                                                "Demo_Complete"
                                                    ? item.DurationInMinutes !=
                                                      0
                                                        ? `${item.DurationInMinutes} Min`
                                                        : "-"
                                                    : `${item.DurationInMinutes} Min`}
                                            </td>
                                            <td
                                                style={{
                                                    maxWidth: "12rem",
                                                    wordBreak: "break-word",
                                                }}>
                                                {item.BatchName}
                                            </td>

                                            <td>
                                                {String(item.EarningType)
                                                    .split("_")
                                                    .join(" ")}
                                            </td>
                                            <td
                                                style={{
                                                    color:
                                                        String(
                                                            item.Credit_Or_Debit
                                                        ).toLowerCase() ===
                                                        "DEBIT".toLowerCase()
                                                            ? "red"
                                                            : "green",
                                                }}>
                                                {item.Credit_Or_Debit != "DEBIT"
                                                    ? isNaN(item.Amount)
                                                        ? 0.0
                                                        : `₹ ${Number(
                                                              item.Amount
                                                          ).toFixed(2)}`
                                                    : "-"}
                                            </td>
                                            <td
                                                style={{
                                                    color:
                                                        String(
                                                            item.Credit_Or_Debit
                                                        ).toLowerCase() ===
                                                        "DEBIT".toLowerCase()
                                                            ? "red"
                                                            : "green",
                                                }}>
                                                {item.Credit_Or_Debit == "DEBIT"
                                                    ? isNaN(item.Amount)
                                                        ? 0.0
                                                        : `₹ ${Number(
                                                              item.Amount
                                                          ).toFixed(2)}`
                                                    : "-"}
                                                <br />
                                                <span>
                                                    {item.Credit_Or_Debit ===
                                                        "DEBIT" &&
                                                        item.IsPenalityChallenged ===
                                                            0 &&
                                                        item.IsPayoutCreated !=
                                                            1 && (
                                                            <button
                                                                className={
                                                                    Earningclasses[
                                                                        "challenge"
                                                                    ]
                                                                }
                                                                custom-id={
                                                                    item.TutorEarningID
                                                                }
                                                                onClick={(e) =>
                                                                    setChallengeId(
                                                                        Number(
                                                                            e.currentTarget.getAttribute(
                                                                                "custom-id"
                                                                            )
                                                                        )
                                                                    )
                                                                }>
                                                                {item.IsPenalityChallenged ===
                                                                2
                                                                    ? "penalty Challenged"
                                                                    : "Challenge Penalty"}
                                                            </button>
                                                        )}
                                                </span>
                                            </td>
                                            <td>
                                                {/*   */}

                                                {item.IsPayoutCreated == 1 &&
                                                item.Credit_Debit_Status ===
                                                    1 ? (
                                                    <span
                                                        className={
                                                            Earningclasses[
                                                                "paid"
                                                            ]
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                item.Credit_Or_Debit ===
                                                                "DEBIT"
                                                                    ? "#EA4335"
                                                                    : "#34A853",
                                                        }}>
                                                        {item.Credit_Or_Debit ===
                                                        "DEBIT"
                                                            ? "Debit"
                                                            : "Credit"}
                                                    </span>
                                                ) : item.CanCreatePayout ==
                                                  0 ? (
                                                    <span
                                                        className={
                                                            Earningclasses[
                                                                "unpaid"
                                                            ]
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                "orange",
                                                        }}>
                                                        Reversed
                                                    </span>
                                                ) : (
                                                    <span
                                                        className={
                                                            Earningclasses[
                                                                "unpaid"
                                                            ]
                                                        }>
                                                        Pending
                                                    </span>
                                                )}
                                                {item.IsPayoutCreated == 0 && (
                                                    <p></p>
                                                )}
                                                <div
                                                    style={{
                                                        marginTop: "0.5rem",
                                                    }}>
                                                    {item.IsPenalityChallenged ===
                                                        1 && (
                                                        <span
                                                            className={
                                                                Earningclasses[
                                                                    "paid"
                                                                ]
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    "#93e2a8",
                                                                borderColor:
                                                                    "transparent",
                                                            }}>
                                                            Challenge Accepted
                                                        </span>
                                                    )}
                                                    {item.IsPenalityChallenged ===
                                                        2 && (
                                                        <span
                                                            className={
                                                                Earningclasses[
                                                                    "pending"
                                                                ]
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    "#94c5f3",
                                                            }}>
                                                            Penalty Challenged
                                                        </span>
                                                    )}
                                                    {item.IsPenalityChallenged ===
                                                        3 && (
                                                        <span
                                                            className={
                                                                Earningclasses[
                                                                    "pending"
                                                                ]
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    "#faada7",
                                                            }}>
                                                            Challenge Rejected
                                                        </span>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td
                                        colSpan={8}
                                        style={{ textAlign: "center" }}>
                                        No Record Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
            {showPaginate && (
                <div
                    style={{
                        justifySelf: "right",
                    }}>
                    <ReactPaginate
                        pageCount={pageCount}
                        // pageRange={2}
                        forcePage={forcepage}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                </div>
            )}
        </>
    );
}

export default Earning;
