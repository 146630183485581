import React, { useEffect, useState } from "react";
import Layout from "Reusable/Tutor/Layout";
import DashboardClass from "./dashboard.module.css";
import { FaRupeeSign } from "react-icons/fa";
import Loader from "Reusable/Common/LoadingComponents/loader/loader";
import { Services } from "Service/service";
import { useForm, SubmitHandler } from "react-hook-form";
import Moment from "react-moment";
import { resetState } from "sweetalert/typings/modules/state";
import { warningSwal } from "Reusable/utils/Toast";
import moment from "moment";
type Inputs = {
    Earning_SummaryType: string;
    FromDate: string;
    ToDate: string;
};
function Dashboard() {
    const [upcomingClasses, setUpcomingClasses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [earning, setEarning] = useState({ NeedToPay: 0, Earnings: 0 });
    let userdata: any = localStorage.getItem("LoginDetails");
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {},
    } = useForm<Inputs>();

    console.log(earning);
    const formData = watch();
    if (userdata) {
        userdata = JSON.parse(userdata);
    }
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        /*    if (data.Earning_SummaryType == "ByDate") {
            if (moment(data.FromDate).isAfter(data.ToDate)) {
                warningSwal("Warning", "Invalid Date range");
                return;
            }
        }
        console.log("1");
        setLoading(true);
        try {
            let body = JSON.stringify({ ...data });
            const [summaryData] = await Promise.all([
                Services.get_earning_class("POST", body, token),
            ]);

            if (summaryData.Status === 1) {
                if (summaryData.Earnings != null) {
                    setEarning({
                        Earnings:
                            summaryData.Earnings != null
                                ? summaryData.Earnings
                                : 0,
                        NeedToPay:
                            summaryData.NeedToPay != null
                                ? summaryData.NeedToPay
                                : 0,
                    });
                }
            }

            setLoading(false);
        } catch {
            setLoading(false);
            alert("something went wrong try again");
        } */
    };

    return (
        <Layout Active={"Cancellation Report"}>
            {loading && <Loader />}
            <div className={DashboardClass["Container"]}>
                <div className={DashboardClass["wrapper"]}>
                    <header>
                        <div>
                            <h3>Cancellation Report</h3>
                            {/*  <p>
                                Welcome to Dashboard here you find overview of
                                you work
                            </p> */}
                        </div>
                        <form
                            className={DashboardClass["filter"]}
                            onSubmit={handleSubmit(onSubmit)}>
                            {/*  <select
                                {...register("Earning_SummaryType", {
                                    required: true,
                                    onChange: (e) => changeHandler(e),
                                })}>
                                <option value="ThisWeek">This Week</option>
                                <option value="ThisMonth">This Month</option>
                                <option value="ByDate">By Date</option>
                            </select> */}
                            <div>
                                <input
                                    type="text"
                                    placeholder="From Date"
                                    {...register("FromDate", {
                                        required: true,
                                    })}
                                    onClick={(event) => {
                                        const inputElement =
                                            event.target as HTMLInputElement;
                                        inputElement.type = "date";
                                    }}
                                />
                                <input
                                    type="text"
                                    placeholder="To Date"
                                    {...register("ToDate", {
                                        required: true,
                                    })}
                                    onClick={(event) => {
                                        const inputElement =
                                            event.target as HTMLInputElement;
                                        inputElement.type = "date";
                                    }}
                                />
                                <button>Submit</button>
                            </div>
                        </form>
                    </header>
                    <div className={DashboardClass["earnings"]}>
                        <div>
                            <p>No. of classes cancelled by the teacher </p>
                            <h3>{Math.round(earning.Earnings)}</h3>
                            <p>(impacted hours)</p>
                        </div>
                        <div>
                            <p>No. of classes cancelled by the parent</p>
                            <h3>{Math.round(earning.NeedToPay)}</h3>
                            <p>(impacted hours)</p>
                        </div>
                        <div>
                            <p>No. of classes cancelled by the Others</p>
                            <h3 style={{ color: "blue" }}>
                                {Math.round(earning.NeedToPay)}
                            </h3>
                            <p>(impacted hours)</p>
                        </div>
                    </div>
                    {/*     <div className={DashboardClass["class-wrapper"]}>
                        <div className={DashboardClass["classes"]}>
                            <h3>Upcoming Classes</h3>
                            <div className={DashboardClass["classes"]}>
                                {upcomingClasses.length > 0 ? (
                                    <>
                                        {upcomingClasses.map((item: any) => (
                                            <div
                                                className={
                                                    DashboardClass[
                                                        "class-details"
                                                    ]
                                                }>
                                                <h3>
                                                    {item?.BatchName} ({" "}
                                                    {item.ClassLessonName}){" "}
                                                    <span
                                                        style={{
                                                            color: "gray",
                                                            fontSize: "0.8rem",
                                                        }}>
                                                        ({item.Duration} min)
                                                    </span>
                                                </h3>
                                                <div>
                                                    <p>
                                                        <Moment format="DD-MM-YYYY">
                                                            {
                                                                new Date(
                                                                    item.Scheduled_StartDate
                                                                )
                                                            }
                                                        </Moment>
                                                    </p>
                                                    <p>
                                                        {
                                                            item.Scheduled_StartTime
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <h6>
                                        Here you can see upcoming classes, you
                                        have no upcoming class
                                    </h6>
                                )}
                            </div>
                        </div>
                        <div></div>
                      
                    </div> */}
                </div>
            </div>
        </Layout>
    );
}

export default Dashboard;
